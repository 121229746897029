import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Modal } from 'react-bootstrap';

const OutletInventories = () => {
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [outlets, setOutlets] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        fetchOutlets();
    }, []);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getAccessToken = () => {
        const accessToken = localStorage.getItem('accessToken');
        console.log('Access Token:', accessToken);
        return accessToken;
    };

    const fetchOutlets = async () => {
        try {
            const accessToken = getAccessToken();
            const response = await fetch('https://d37sta01pkk8ye.cloudfront.net/api/v1/outlet', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const outletsData = await response.json();
            setOutlets(outletsData);
        } catch (error) {
            console.error('Error fetching outlets:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCurrentPage(0); // Reset page number when fetching new data
        if (selectedOutlet) {
            await fetchOutletInventories(selectedOutlet, 0, pageSize);
        }
    };

    const fetchOutletInventories = async (outletName) => {
        try {
            const accessToken = getAccessToken();
            const encodedOutletName = encodeURIComponent(outletName);
            const response = await fetch(`https://d37sta01pkk8ye.cloudfront.net/api/v1/inventory/outlet/${encodedOutletName}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch outlet inventories');
            }
            const data = await response.json();
            console.log(data);
            console.log('OutletName: ', outletName);
            
            // Check if data is an array
            if (Array.isArray(data)) {
                setInventories(data);
            } else {
                console.error('Error: Outlet inventories data is not an array');
            }
            
            handleCloseModal();
        } catch (error) {
            console.error('Error fetching outlet inventories:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchOutletInventories(selectedOutlet, page, pageSize);
    };

    return (
        <>
            <div style={{ height: '510px', overflowY: 'auto' }} className="shadow-md sm:rounded-lg mt-16 p-4">
                <h1>Outlet Inventory</h1>
                <Button variant="primary" onClick={handleShowModal}>Choose Outlet</Button>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select Outlet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formOutletSelect">
                            <Form.Label>Select Outlet</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={(e) => setSelectedOutlet(e.target.value)}
                                value={selectedOutlet}
                            >
                                <option value="">Select Outlet</option>
                                {outlets.map((outlet) => (
                                    <option key={outlet.outletId} value={outlet.outletName}>
                                        {outlet.outletName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                        <Button variant="primary" onClick={handleSubmit}>Fetch Inventories</Button>
                    </Modal.Footer>
                </Modal>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th scope="col" className="fixed-th text-center">
                                Id
                            </th>
                            <th scope="col" className="fixed-th text-center">
                                Variant
                            </th>
                            <th scope="col" className="fixed-th text-center">
                                Engine Number
                            </th>
                            <th scope="col" className="fixed-th text-center">
                                Colour
                            </th>
                            <th scope="col" className="fixed-th text-center">
                                VIN
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventories.map((inventory) => (
                            <tr key={inventory.id}>
                                <td>{inventory.id}</td>
                                <td>{inventory.variantDescription}</td>
                                <td>{inventory.engineNumber}</td>
                                <td>{inventory.colorDescription}</td>
                                <td>{inventory.vin}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {totalPages > 0 && (
                    <div>
                        <Button variant="outline-secondary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>Previous</Button>{' '}
                        <Button variant="outline-secondary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1}>Next</Button>
                    </div>
                )}
            </div>
            </>
    );
};

export default OutletInventories;
