export const setEmployeeDetails = (employeeDetails) => ({
    type: 'SET_EMPLOYEE_DETAILS',
    payload: employeeDetails,
  });
  
  // reducers.js
  const initialState = {
    employeeDetails: null,
  };
  
  const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_EMPLOYEE_DETAILS':
        return {
          ...state,
          employeeDetails: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default rootReducer;