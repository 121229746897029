import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

const TransferDetails = () => {
    const [transfers, setTransfers] = useState([]);

    useEffect(() => {
        fetchTransfers();
    }, []);

    const getAccessToken = () => {
        const accessToken = localStorage.getItem('accessToken');
        console.log('Access Token:', accessToken);
        return accessToken; // Add this line
    };

    const fetchTransfers = async () => {
        try {
            const accessToken = getAccessToken();
            const response = await fetch('https://d37sta01pkk8ye.cloudfront.net/api/v1/transfers', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch transfers');
            }
            const data = await response.json();
            setTransfers(data);
        } catch (error) {
            console.error('Error fetching transfers:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
    };

    const formatTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        let hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${hours}:${minutes} ${ampm}`;
    };

    return (
        <>
            <div style={{ height: '510px', overflowY: 'auto' }} className="shadow-md sm:rounded-lg mt-16 p-4">
                <h2>Transfers</h2>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Transfer ID</th>
                            <th>Transfer Date</th>
                            <th>Time</th>
                            <th>Outlet Name</th>
                            <th>Number of Inventories</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transfers.map((transfer) => (
                            <tr key={transfer.transferId}>
                                <td>{transfer.transferId}</td>
                                <td>{formatDate(transfer.transferDate)}</td>
                                <td>{formatTime(transfer.transferDate)}</td>
                                <td>{transfer.outlet ? transfer.outlet.outletName : 'Unknown'}</td>
                                <td>{transfer.numberOfInventories}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default TransferDetails;